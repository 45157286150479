<template>
	<div class="container my-2">
		<div class="row">
			<div class="col-12 mx-auto">
				<el-page-header
					class="mb-3"
					@back="goBack"
					:content="postInfo.title"
					title="返回"
				>
				</el-page-header>
				<div class="d-flex my-2 align-items-center text-start">
					<el-avatar size="large" :src="postInfo.author.avatar"></el-avatar>
					<div class="ms-3">
						<p class="mb-2">{{ postInfo.author.name }}</p>
						<p>{{ postInfo.createDate }}</p>
					</div>
				</div>

				<div
					class="d-flex py-1 border-top border-bottom border-1 py-2 px-2 mb-3"
				>
					<el-button v-if="postInfo.isLike" class="" link @click="removeLike()">
						<i
							class="fa-duotone fa-thumbs-up fw-bold"
							style="--fa-primary-color: #4134fe; --fa-secondary-color: #fe9b10"
						></i>
						<span class="ms-2">{{ postInfo.likes.length }}</span>
					</el-button>
					<el-button v-else class="" link @click="sendLike()">
						<i class="PostBtn fal fa-thumbs-up"></i>
						<span class="ms-2">{{ postInfo.likes.length }}</span>
					</el-button>

					<el-button class="ms-3" link @click="showCommentBox()">
						<i class="PostBtn fal fa-comment"></i>
						<span class="ms-2">{{ postInfo.commentCount }}</span></el-button
					>
					<!-- <el-button class="ms-3" link @click="sharedLink()">
            <i class="PostBtn fal  fa-copy"></i>
          </el-button> -->
					<el-button
						v-if="postInfo.likes.length > 0"
						class="ms-auto d-flex align-items-center"
						link
						@click="showLikeUsers()"
					>
						<template v-if="postInfo.likes.length >= 3">
							<el-avatar
								v-for="like in postInfo.likes.slice(0, 3)"
								:key="like"
								:size="25"
								:src="like.userAvatar"
							></el-avatar>
							<i class="far fa-ellipsis-h ms-1"></i>
						</template>
						<template v-else>
							<el-avatar
								v-for="like in postInfo.likes"
								:key="like"
								:size="20"
								:src="like.userAvatar"
							></el-avatar>
						</template>
					</el-button>
				</div>

				<div class="d-flex flex-wrap">
					<el-tag
						:key="tag"
						class="me-2 mb-3"
						v-for="tag in postInfo.tags"
						:disable-transitions="false"
					>
						#{{ tag.label }}
					</el-tag>
				</div>
				<video
					v-if="postInfo.videoPath != ''"
					:src="postInfo.videoPath"
					controls
					class="w-100 my-2"
				></video>
				<div class="text-start" id="ckeditorBox">
					<div v-html="sanitizeHTML(postInfo.content)"></div>
					<ckeditor
						v-if="1 == 0"
						id="ckeditor"
						:editor="editor"
						v-model="postInfo.content"
						:config="editorConfig"
						disabled
					></ckeditor>
				</div>
			</div>
		</div>
	</div>

	<el-drawer
		v-model="commentDraw"
		title="回應"
		:size="$store.getters.mobileSize ? '100%' : '40%'"
	>
		<div>
			<el-card
				style="top: 0px"
				class="position-sticky"
				:body-style="{
					padding: '10px',
				}"
			>
				<div class="d-flex align-items-center mb-2">
					<el-avatar size="small" :src="currentUser().avatar"></el-avatar>
					<span class="ms-2">{{ currentUser().name }}</span>
				</div>
				<el-input
					v-model="textarea"
					maxlength="30"
					placeholder="說些什麼"
					show-word-limit
					type="textarea"
					:autosize="{ minRows: 3, maxRows: 6 }"
					class="mb-2"
				/>
				<el-button
					class="ms-auto"
					type="success"
					round
					:disabled="textarea == ''"
					@click="sendComment()"
					>回應</el-button
				>
			</el-card>

			<div
				v-for="(i, index) in comments"
				:key="i"
				class="my-3 px-2"
				:class="index != 0 ? 'border-top' : ''"
			>
				<div class="d-flex my-2 align-items-center text-start">
					<el-avatar size="small" :src="i.userAvatar"></el-avatar>
					<div class="ms-3">
						<p class="mb-1">{{ i.userName }}</p>
						<p style="color: rgba(0, 0, 0, 0.5); font-size: small">
							{{ i.createTime }}
						</p>
					</div>

					<el-dropdown
						class="ms-auto"
						trigger="click"
						v-if="i.userId == currentUser().id"
					>
						<el-button class="" link>
							<i class="far fa-ellipsis-v"></i>
						</el-button>
						<template #dropdown>
							<el-dropdown-menu>
								<el-dropdown-item type="text" @click="addContactBook"
									>編輯</el-dropdown-item
								>
								<el-dropdown-item type="text" @click="getTemplate">
									<span class="text-danger" @click="opendeleteComfirm(i.id)"
										>刪除</span
									>
								</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div>
				<div class="text-start mt-2">{{ i.content }}</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";

export default {
	components: {
		ckeditor: CKEditor.component,
	},
	data() {
		return {
			editor: ClassicEditor,
			textarea: "",
			commentDraw: false,
			postInfo: {
				content: "",
				title: "",
				author: {
					name: "",
					avatar: "",
					createDate: "",
				},
				published: false,
				commentCount: "",
				isLike: false,
				likes: "",
				videoPath: "",
			},
			comments: [],
			editorConfig: {
				toolbar: [],
				readOnly: true,
			},
		};
	},
	methods: {
		loadData() {
			let vm = this;

			vm.fetchAPI(
				"get",
				`${process.env.VUE_APP_URL_API}/Post/GetPost/${this.$route.params.id}`
			)
				.then((res) => {
					vm.postInfo = res.data;
					console.log(vm.postInfo);
				})
				.catch((err) => {
					vm.savedTxt = "儲存失敗請稍後再試";
					console.log(err);
				});
		},
		showCommentBox() {
			let vm = this;
			vm.fetchAPI(
				"get",
				`${process.env.VUE_APP_URL_API}/Post/Comment/Get/${this.$route.params.id}`
			)
				.then((res) => {
					vm.comments = res.data;
				})
				.catch((err) => {
					vm.savedTxt = "儲存失敗請稍後再試";
					console.log(err);
				});
			vm.commentDraw = true;
		},
		sendLike() {
			let vm = this;
			let postId = vm.$route.params.id;
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Post/Like/Add/${postId}`,
				{},
				false
			).then((res) => {
				console.log(res);
				vm.postInfo.isLike = true;
				vm.postInfo.likes.unshift({
					userId: vm.currentUser().id,
					userAvatar: vm.currentUser().avatar,
				});
			});
		},
		removeLike() {
			let vm = this;
			let postId = vm.$route.params.id;
			vm.fetchAPI(
				"delete",
				`${process.env.VUE_APP_URL_API}/Post/Like/Remove/${postId}`,
				{},
				false
			).then((res) => {
				console.log(res);
				vm.postInfo.isLike = false;
				vm.postInfo.likes = vm.postInfo.likes.filter(
					(x) => x.userId != vm.currentUser().id
				);
			});
		},
		sharedLink() {
			const currentUrl = window.location.href;
			navigator.clipboard
				.writeText(currentUrl)
				.then(() => {
					this.notify("success", "複製成功！");
				})
				.catch(() => {
					this.notify("error", "複製失敗！");
				});
		},
		sendComment() {
			let vm = this;
			const data = {
				postId: vm.$route.params.id,
				content: vm.textarea,
			};
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/Post/Comment/Add`,
				data
			)
				.then((res) => {
					console.log(res.data);
					vm.comments.unshift({
						id: res.data.id,
						content: vm.textarea,
						userId: vm.currentUser().id,
						userName: vm.currentUser().name,
						userAvatar: vm.currentUser().avatar,
						createTime: "剛剛",
					});
					vm.textarea = "";
					vm.postInfo.commentCount++;
				})
				.catch((err) => {
					vm.savedTxt = "儲存失敗請稍後再試";
					console.log(err);
				});
		},
		opendeleteComfirm(id) {
			let vm = this;
			this.$confirm("確定刪除此回應嗎?", "提示", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					vm.fetchAPI(
						"delete",
						`${process.env.VUE_APP_URL_API}/Post/Comment/Delete/${id}`,
						{},
						false
					).then((res) => {
						console.log(res);
						vm.comments = vm.comments.filter((x) => x.id != id);
						this.$message({
							type: "success",
							message: "刪除成功!",
						});
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消",
					});
				});
		},
		goBack() {
			this.$router.go(-1);
		},
		sanitizeHTML(html) {
			return html
				.replace(/(<img\s[^>]*?)\s(width|height)="[^"]*"([^>]*>)/gi, "$1$3")
				.replace(
					/<img\s([^>]*?)\s(width|height)="[^"]*"([^>]*?)>/gi,
					(match, p1, p2, p3) => {
						// 檢查是否已經有 style 屬性
						const hasStyle = /style="[^"]*"/.test(p1);
						if (hasStyle) {
							// 如果有 style 屬性，則添加 width: 100%
							return `<img ${p1.replace(
								/style="([^"]*)"/,
								(match, styleContent) => `style="${styleContent}; width: 100%"`
							)} ${p3}>`;
						} else {
							// 如果沒有 style 屬性，則創建 style 屬性
							return `<img ${p1} style="width: 100%; height: auto; aspect-ratio:1074/717;" ${p3}>`;
						}
					}
				);
		},
	},
	mounted() {
		let vm = this;
		vm.loadData();
	},
};
</script>

<style lang="scss" scoped>
.el-card {
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* 自定义阴影效果 */
}
.chargemeAI-title {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
